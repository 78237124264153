/* You can add global styles to this file, and also import other style files */
.modal-scrollbar {
  /* Pour les navigateurs basés sur WebKit (Chrome, Safari) */
  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #ffbc33;
    border-radius: 4px; /* Ajoutez une bordure arrondie à la poignée de la barre de défilement */
  }

  &::-webkit-scrollbar-track {
    background-color: transparent; /* Couleur de fond de la barre de défilement */
  }

  /* Pour les autres navigateurs */
  scrollbar-width: thin;
  scrollbar-color: #ffbc33 transparent;

  /* Appliquer le style au formulaire spécifiquement */
  form {
    /* Ajustez la largeur de la barre de défilement du formulaire selon vos préférences */
    scrollbar-width: thin;
    scrollbar-color: #ffbc33 transparent;

    /* Ajoutez d'autres styles au besoin */
  }

  /* Styles pour le reste de la modal */
  .addContainer {
    /* Ajoutez d'autres styles au besoin */
  }

  /* Autres styles spécifiques à la modal */
  .modalHeader {
    /* Ajoutez d'autres styles au besoin */
  }

  .example-button-row {
    display: flex;
    justify-content: flex-end; /* Aligner les boutons à droite */
    margin-top: 16px; /* Ajuster la marge au besoin */
  }

  button {
    margin-left: 8px; /* Ajouter un espacement entre les boutons */
  }

  // Classe pour activer un élément
  .activer {
    pointer-events: auto; // Permet les événements de pointeur
    opacity: 1; // Opacité à 100%
  }

  // Classe pour désactiver un élément
  .desactiver {
    pointer-events: none; // Désactive les événements de pointeur
    opacity: 0.5; // Réduit l'opacité à 50%
  }
}


// Classe pour activer un élément
.activer {
  pointer-events: auto; // Permet les événements de pointeur
  opacity: 1; // Opacité à 100%
}

// Classe pour désactiver un élément
.desactiver {
  pointer-events: none; // Désactive les événements de pointeur
  opacity: 0.5; // Réduit l'opacité à 50%
}

/* Styles for delete box */
ul.checkmark-list {
  list-style-type: none; /* Retire le disque noir par défaut */
  padding-left: 0;
}

li.circle-checkmark {
  position: relative;
  margin-bottom: 10px;
  padding-left: 20px;
  line-height: 1.5;
}

li.circle-checkmark::before {
  content: "";
  position: absolute;
  left: 0;
  top: 2px;
  border: solid 8px #f67305;
  border-radius: 8px;
}

li.circle-sub-checkmark::before {
  content: "";
  position: absolute;
  left: 0;
  top: 2px;
  border: solid 8px #067214;
  border-radius: 8px;
}


li.circle-checkmark::after {
  content: "";
  position: absolute;
  left: 5px;
  top: 5px;
  margin: 1px;
  width: 3px;
  height: 8px;
  border: solid #1e0202;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

li.circle-sub-checkmark::after {
  content: "";
  position: absolute;
  left: 5px;
  top: 5px;
  margin: 1px;
  width: 3px;
  height: 8px;
  border: solid #ffffff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

/* Styles for nested list */
ul.nested-list {
  padding-left: 20px;
}

/* Styles for delete box */

/* icon style colors */
.icon-color-default {
  color: #fff !important;
}

.icon-color-marron {
  color: #632800 !important;
}

/* end icon style colors */

// styles.scss
:root {
  --color-primary: #3f51b5;
  --color-accent: #e91e63;
  --color-warn: #f44336;
  --color-green: #4caf50;
  --color-marron: #795548;
  --color-yellow: #ffeb3b;
  --color-grey: #9e9e9e;
}

.color-primary {
  background-color: var(--color-primary) !important;
  color: white;
}

.color-accent {
  background-color: var(--color-accent) !important;
  color: white;
}

// Ajoutez des classes pour chaque couleur
